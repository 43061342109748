.real_estate_image{
  width: 150px;
  height: 150px;
}

.real_estate_image img{
  width: 100%;
}


.real_estate_images{
  display: flex;
  align-items: center;
  gap: 20px;
}