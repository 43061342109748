.rowDiv {
  display: flex;
  gap: 10px;
}
.w-100 {
  width: 100%;
}
.spaceBetween {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}

.color-white {
  color: white;
}


.columnDiv{
  display: flex;
  gap: 20px;
  flex-direction:column;
}


[id^="cell-"][id*="-"] {
  min-width: 200px;
  max-width: 200px;
  text-overflow: ellipsis;
}

.toggle-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.downPayment {
  /* display: flex; */
  gap: 10px;
  /* align-items: flex-start; */
}

.downPayment>p{
  height:100% !important;
}

.signature-component{
  padding-left:15px !important;
  padding-right:15px !important;
  border-radius:10px !important;
}

.images img {
  width: 200px;
  margin: 10px 0;
}